@import url('https://fonts.googleapis.com/css2?family=Stylish&family=Tajawal:wght@500&display=swap');
section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.top-p{
  font-size: 20px;
    color: #000000 !important;
    font-weight: 100;
}
.see-more{
    text-decoration:none;
    font-weight: 600;
    font-size: 15px;
    color: #FA5456;
}
.text-right {
  text-align: right;
}
.flex-grow-1{
  flex-grow: 1;
}
.main-color {
  color: #FA5456;
}
.ar-dir{
  direction: rtl;
  text-align: right;
}
.answer-label{
  margin: 0px 5px;
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    padding: 10px 2.8rem;
    cursor: pointer;
}
.answer-label-radio{
  padding: 0 2rem !important;
}
.main-icon{
    background: #e9fcf8;
    border-radius: 5px;
    padding: 10px 12px 10px 10px;
    position: relative;
    cursor: pointer;
}
.secondry-color {
  color: #FA5456;
}
.main-title {
  color: #08cad7;
  font-weight: 600;
}
.danger-title {
  color: #FA5456;
  font-weight: 600;
}
.main-black-title {
  font-weight: bold;
  color: #6e6e6e;
  margin-bottom: 15px;
}
.main-margin-top {
  margin-top: 5rem;
}
.text-font-muted {
  color: #b3b3b3;
  font-weight: 500;
}
.text-muted {
  color: #b3b3b3;
  font-weight: 500;
}
.font-weight-bold {
  font-weight: bold;
}
.main-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  margin-bottom: 25px;
  border: none;
}
.bg-Progress-Success {
  background-color: #07b2bd !important;
}
.bg-Progress-Failed {
  background-color: #FA5456 !important;
}
.bg-Progress-Improve {
  background-color: #ffb448 !important;
}
.card {
  border-radius: 8px !important;
}
.card .card-img-top {
  border-radius: 8px !important;
}

.img-input-placeholder{
    position: absolute;
    display: inline-block;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

/* modal */
.modal-header {
  border-bottom: 0;
}
.modal-footer {
  border-top: 0;
}

a {
  text-decoration: none;
}

/* nav tabs */
.nav-tabs .nav-item {
  margin-bottom: 5px;
}

body > iframe {
  display: none;
}

@media (min-width: 769px) {
  .card-item-container{
    padding: 0;
    margin-bottom: 20px;
  }
  .card-item-container .card-body{
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
  .list-item-card,
  .card-item-container > div{
    height: 100%;
  }
  .card-item-container > div{
    width: calc(100% - 40px);
  }
  .card-item-container:nth-child(3n) > div { 
    margin-left: auto;
  }
  .card-item-container:nth-child(3n + 1) > div {
    margin-right: auto;
  }

  .card-item-container:nth-child(3n - 1) > div {
    margin: auto;
  }
  
  .get-in-touch{
    padding-top: 7rem ;
    padding-bottom: 7rem ;
  }
  .get-in-touch p{
    width: 60%;
    margin: 15px auto 40px;
  }
  .home-banner img{
    height: 604px;
  }
  .home-banner.pop-up img{
    height: auto;
  }
  .courses-list .course-image img{
    width: 180px;
    height: 160px
  }
  .modal-dialog {
    min-width: 560px;
  }
  .hideScroll {
    overflow-x: auto;
    white-space: nowrap;
  }
}

.allSessionsContainer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.allSessionsContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.allSessionsContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #999, #888);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
}
/*calender style*/
.rmdp-container {
  position: relative;
}
.rmdp-input {
  border-radius: 15px;
  height: 45px;
  padding: 0 1.5rem;
  width: 125%;
  background: transparent;
  color: transparent;
  border: none;
}
.rmdp-input:focus {
  border: none;
  box-shadow: none;
}

/*drop down*/
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: white !important;
  color: #848484 !important;
  border-color: var(--bs-btn-active-border-color);
}

.dropdown-toggle::after {
  right: 2rem;
  position: absolute;
}

.dropdown-menu.show {
  width: fit-content;
  border: none;
  max-height: 300px;
  overflow-y: auto;
}
.share-dropdown .dropdown-menu.show{
  width: fit-content !important;
}

.date-dropdown .dropdown-menu.show {
  width: max-content !important;
}

.dropdown .form-control:focus {
  box-shadow: none;
  border-color: #fff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
  color: #848484;
  padding: 14px 25px;
  border-radius: 10px;
  border-color: var(--maincolor);
}
.css-13cymwt-control {
  height: 48px;
  margin: 0px 5px;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
  border: none;
  color: #848484;
  padding: 5px 5px 15px 15px;
  border-color: transparent;
  border: none !important;
}

.css-1p3m7a8-multiValue {
  background-color: #fed21a !important;
  border-radius: 5px !important;
  padding: 2px 5px !important;
  color: #000 !important;
  border-color: none !important;
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: #fed21a !important;
  color: #000 !important;
}
.hideScroll {
  padding-bottom: 10px;
}
.hideScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.hideScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.hideScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #FA5456;
}
.notifications-box::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.notifications-box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.notifications-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #FA5456;
}

.box-border {
  padding: 1rem;
  border: 1px solid #cfcfcf;
  border-radius: 15px;
}

.courses-list{
  margin: 10px;
}
.course-rating{
  display: block !important;
}
.rate-number{
  margin: 0 !important;
}
.courses-list .course-item .course-price{
  padding: 0 !important;
  text-align: left;
}
.courses-list .course-item{
  padding: 0.5rem 1rem;
}
.courses-list .course-details{
  padding: 0 10px !important;
}
.courses-list .course-item h4{
  margin: 10px 0 20px;
  padding: 0;
  
}
.show-sidebar-icon{
  display: none;
}
.top-user-icons{
  display: none;
}

.top-user-icons .dropdown-menu {
  width: 300px;
  right: 0;
  left: auto;
}
.top-user-icons #dropdown-basic{
  border: none !important;
    background: #fff;
}
.top-user-icons .dropdown-toggle::after {
  border: 0;
}
.achievement-container{
  justify-content: space-evenly;
}


@media (max-width: 768px) {
  .share-dropdown{
    flex-direction: column;
  }
  .notifications-box{
    right: -8.5rem !important;
    width: 350px !important;
  }
  .top-web-icon{
    display: none !important;
  }
  .top-user-icons{
    display: block;
  }
  .show-sidebar-icon{
    display: block;
  }
  .courses-feedback{
    padding: 0 10px
  }
  .mobile-hidden{
    display: none;
  }
  .courses-list .course-item h4{
    
    font-size: 17px;
  }
  .courses-list .course-image{
    margin: 10px 10px 0px 0px
  }
  .courses-list .course-image img{
    width: 100px;
    height: 100px
  }
  .custom-tab-button{
    font-size: 15px !important;
    margin-bottom: 1rem !important;
  }
  .join-consultants{
    text-align: center;
  }
  .join-consultants button{
    margin-bottom: 2rem;
  }
  .hideScroll {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .hideScroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }
  .hideScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparent;
  }
  .achievement-container{
    justify-content: normal;
  }
  .achievement-card{
    margin: 10px 10px 0 10px !important;
  } 
  .achievement-card h4{
    width: 200px;
  }

  .availablity-container{
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .availablity-container > div{
    flex: 1 0 auto;
  }
  .availablity-container > div:nth-child(1){
    flex-basis: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
  }
  .availablity-container > div:nth-child(2){
    display: none;
  }
  .availablity-container > div:nth-child(3),
  .availablity-container > div:nth-child(4){
    flex-basis: 50%;
  }
  
}
.cursor-pointer,
.rmdp-input{
  cursor: pointer;
}
.red{
  color: red;
}
.card .card-img-top {
  height: 250px;
}
.event-card-details.card .card-img-top {
  height: auto;
}
.mentor.card .card-img-top {
  height: 380px;
}
.form-check{
  margin-bottom: .5rem;
}


@media (max-width:669px) {
  .top-head-sidebar {
      margin-bottom: 1rem;
  }
  .user-profile-side-bar{
      position: absolute;
      width: 70%;
      background: #fff;
      left: -1000px;
      z-index: 4;
  }
  .ranking-name{
    font-size: 15px !important;
  }
  .top-head-consultant{
    display: none;
  }
  .header{
    height: auto !important;
    padding: 20px 0;
    margin-bottom: 1rem !important;
  }
  .consultant-card{
    min-height: auto;
    margin-top: 20px;
  }
  .consultant-tabs{
    overflow: scroll;
    flex-wrap: nowrap;
    margin-bottom: 0rem !important;
  }
  .ConsultantTabsContainer .tab-content{
    padding: 1rem;
  }
  .consultant-tabs li{
    white-space: nowrap;
  }
  .consultant-tabs::-webkit-scrollbar {
    width: 0px;
  }
  .comming-soon{
    padding: 0 !important;
  }
  .comming-soon h1{
    font-size: 30px !important;
  }
  .comming-soon .main-head{
    margin-top: 10px !important;
  }
  .comming-soon .main-head p{
    font-size: 22px !important;
  }
  .comming-soon .main-head p:last-child {
    font-size: 16px !important;
}
  .store-icons{
    display: flex;
    justify-content: center;
  }
}
.social-popup iframe{
  width: 100%;
}
.my-courses-list .search-container{
  display: none;
}
.activity-icon{
  margin: 0 20px 0 0;
}
.ar-dir-container .activity-icon{
  margin: 0 0 0 20px;
} 
.Filter{
  width: 50px;
  background: #e9fcf8;
  border-radius: 10px;
  padding: 10px 12px 10px 10px;
  position: relative;
  cursor: pointer;
}
.react-tel-input > input{
    height: 48px !important;
    padding: 14px 40x !important;
    margin: 0px 5px !important;
    border-radius: 15px !important;
    background-color: #fff !important;
    border: none !important;
    color: #848484 !important;
    width: 100% !important;
}
.position-relative{
  position: relative;
}
.top-label{
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  border-radius: 0px 10px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #07b2bd;
  padding: 3px 10px;
}
.achievement-card{
    padding: 2rem;
    background: #E1F4FF;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 40px;
    width: 250px;
}
.achievement-card .ach-number{
  font-size: 26px;
  font-weight: 700;
}
.achievement-card .go{
  width: 25px;
}
.achievement-card.challanges{
  background: #E1F4FF;
}
.achievement-card.challanges h4,
.achievement-card.challanges .ach-number{
  color: #008993;
}


.achievement-card.follow-up{
  background: #FFF4E8;
}
.achievement-card.follow-up h4,
.achievement-card.follow-up .ach-number{
  color: #FF9B26;
}

.achievement-card.courses{
  background: #FFEFF0;
}
.achievement-card.courses h4,
.achievement-card.courses .ach-number{
  color: #FA5456;
}

.achievement-card.sessions{
  background: #FDF4FF;
}
.achievement-card.sessions h4,
.achievement-card.sessions .ach-number{
  color: #72048B;
}

.achievement-card.invitation{
  background: #F9F9F9;
}
.achievement-card.invitation h4,
.achievement-card.invitation .ach-number{
  color: #5B5B5B;
}

.home-banner .carousel-indicators button{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
}

.home-banner .carousel-indicators .active{
  background-color: #FA5456;
}
.containers-tab .nav-tabs .nav-item{
  flex-grow: 1;
}

.containers-tab .nav-tabs .nav-item button{
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}

.containers-tab .nav-tabs .nav-item button svg{
  margin: 0 5px;
  vertical-align: bottom;
}

.containers-tab .custom-web-tabs.nav-tabs .nav-link{
  border: none;
  border-radius: 5px;
  color: #B0B0B0;
  background: #fff;
  margin-right: 10px;
  width: calc(100% - 10px);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containers-tab .custom-web-tabs.nav-tabs .nav-link.active{
  background: #FA5456 ;
  color: #fff ; 
}

.child-tabs button.active{
  
  background: #00759614 !important;
  color: #008993 !important;
}

.rank-details{
  padding: 20px;
  border-radius: 10px;
}

.first-rank{
  background: #FFFCE2;
  color: #3C69BE;
}

.second-rank{
  background: #E3F6FF;
  color: #3C69BE;
}

.third-rank{
  background: #ECF4FF;
  color: #3C69BE;
}

.normal-rank{
  background:#F4F4F4;
  color: #9B9B9B;
  padding: 30px 27px !important;
}

.rank{
  
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}

.info-img{
  width: 20px; height: 20px; cursor: pointer;
}
.invite-desc{
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 10px;
}



.home-custom-card{
  box-shadow: none;
  border-radius: 5px !important;
  overflow: hidden;

}
.card-custom-body{
  background-color: #f9f9f9;
  padding-top: 20px;
}
.subscribed-label{
  background-color: #FAD01C;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
  border-radius: 26px;
  padding: 5px 15px;
  margin: 0 10px;
  
}

.price-label{
  background-color: #FF5B5B;
  color: #F5F5F5;
  font-weight: 500;
  font-size: 14px;
  border-radius: 26px;
  padding: 5px 15px;
  text-transform: capitalize;
}

.type-label{
  background-color: var(--maincolor);
  color: #F5F5F5;
  font-weight: 500;
  font-size: 14px;
  border-radius: 26px;
  padding: 5px 15px;
  text-transform: capitalize;
}

.top-card-label{
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}
.top-card-label > span{
  margin-bottom: 10px;
  display: inline-block;
}
.top-card-label > img{
  margin-left: auto;
}
.card-custom-title{
  color: #000;
  font-weight: 500;
  font-size: 21px;
}
.card-custom-price{
  color: #FF5B5B;
  font-size: 18px;
  font-weight: 700;
}
.card-custom-provider{
  color: #000;
  font-weight: 400;
  font-size: 15px;
  margin: 10px 0;
}
.card-custom-date{
  color: #787878;
  font-size: 14px;
  margin: 0 5px;
}
.card-custom-specialize{
  font-size: 13px;
  font-weight: 300;
  color: #000;
}
.card-custom-button{
  background: #FA5456;
  border-radius: 33px;
  color: #fff;
  padding: 15px;
  text-align: center;
  margin: 10px auto 0;
  width: 100%;
  border: none;
  font-size: 18px;
  font-weight: 500;
}
.card-lang{
  color:#FF5B5B;
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
}
.react-multi-carousel-dot button{
  border: none !important;
}
.react-multi-carousel-dot--active button{
    background: #FA5456 !important;
    border: none;
    width: 35px !important;
    border-radius: 6px !important;
}

.react-multi-carousel-track{
  margin-bottom: 40px !important;
}
.get-in-touch{
  background: linear-gradient(0, #F7F7F7, #FFFBDC);
}

.track-name{
  font-size: 27px;
  font-weight: 600;
  color: #070707;
  margin: 0;
}
.track-provider{
  background: #00759614;
  color: #008993;
  border-radius: 29px;
  padding: 5px 15px;
  margin: 0 10px;
}
.track-desc-title{
  font-size: 20px;
  font-weight: 600;
  color: #070707;
}
.track-desc-content{
  font-size: 16px;
  font-weight: 300;
  color: #000000;
}
.track-see-more{
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  margin: 0 5px;
}

.act-day-number{
  font-size: 18px;
  font-weight: 500;
}
.act-day-name{
  font-size: 12px;
  font-weight: 500;
  color: #949199;
  text-transform: uppercase;
}
.act-day-name.active{
  color: #fff !important;
}
.points-title{
  font-size: 18px;
  font-weight: 400;
  color: #070707;
}
.points-val{
  color: #FF5B5B;
  font-weight: 700;
}
.shareDropdown #dropdown-custom{
  padding: 0 10px 0 5px;
}
.Sign-in .card-button{
  background: #FF5B5B;
  border-color: #fff;
  border-radius: 8px;
  font-weight: 700;
}
.contact-header{
  height: 220px;
  background-color: #C3FBFF;
  margin-bottom: 3rem;
}
.contact-header .breadcrumb-header{
  font-size: 33px;
}

.contact-header .breadcrumb-details span{
  font-size: 16px;
  font-weight: 300;
  color: #787878;
}

.contact-item p{
text-align: left;
margin: 0;
color: #2E4450;
font-weight: 500;
}

#filterContainer{
  display: none;
}
.list-item-card{
  box-shadow: none ;
  border: 1px solid #F5F5F5 ;
  background: #FAFAFA;
}
.top-list-filters{
  background: #24A1AB;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}
.top-filter-type{
  font-weight: 400;
  font-size: 15px;
  color: #F9F9F9;
}
.top-list-filters .btn-check:checked + .btn,
.top-list-filters .btn.active,
.top-list-filters .btn.show,
.top-list-filters .btn:first-child:active,.top-list-filters :not(.btn-check) + .btn:active{
  background-color: transparent !important;
  border: none;
}

.top-list-filters .filter-type-options{
  padding: 10px 10px 0;
}

.filter-type-options .custom-input{
  width: 100%;
}

.search-icon{
  cursor: pointer;
}
.submit-date{
    background-color: #FA5456;
    color: #F5F5F5;
    font-weight: 500;
    font-size: 14px;
    border-radius: 26px;
    padding: 0px 15px;
    text-transform: capitalize;
    border: none;
    margin: 0 5px;
}
.rate-course-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fed21a;
    padding: 10px 30px;
    border-radius: 20px;
    font-weight: 600;
}




@media (max-width: 768px) {
  .top-list-filters{
    margin-left: 1px;
    margin-right: 1px;
  }
  .top-filter-container{
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
  }
  .top-filter-type {
      font-size: 13px;
  }
  .get-in-touch{
    padding: 1rem;
  }
  .get-in-touch p{
    margin-bottom: 15px;
  }
  .get-in-touch a{
    margin-bottom: 15px;
    display: block;
  }

}
.ql-hidden{
  display: none;
}

.consultnant-profile-card .card-item-container>div, .consultnant-profile-card .list-item-card{
  height: auto;
}

.form-check-input:checked {
  background-color: #FA5456;
  border-color: #FA5456;
}
.submitted-at{
  font-size: 13px; font-weight: 300; color: rgb(120, 120, 120);
}

/*custom tabs*/
.containers-tab .nav-item{
  flex-grow: 1;
}
.containers-tab .nav-link{
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #B0B0B0;
  background: #fff;
  margin-right: 10px;
  width: calc(100% - 10px);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
    font-weight: 500;
}
.containers-tab .nav-link.active {
  background: rgba(235, 113, 0, 0.1) !important;
  color: #EB7100 !important;
  font-weight: 600;
}
/*custom tabs/

/*quiz*/
.quiz-title{
  font-size: 20px;
  display: flex;
  justify-content: center;
  color: var(--maincolor);
  font-weight: 700;
}
.quiz-desc{
  color: #3E3E3E;
  font-size: 15px;
  font-weight: 400;
}
.quiz-notes li{
  color: #3E3E3E;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  width: fit-content;
  border-bottom: 1px solid #E9E9E9;
  padding: 10px 0px;
}
.quiz-quetion-header{
  cursor: pointer;
}
.choose-answer{
  color: #959595;
  font-size: 15px;
  font-weight: 400;
}
.quiz-quetion{
  color: #3E3E3E;
  font-size: 20px;
  font-weight: 500;
}
.quiz-popup-title{
  color: var(--Grey, #4F4F4F);
  font-size: 20px;
  font-weight: 700;
}
.quiz-popup-desc{
  color: var(--Color-Grey-200, #A3A3A3);
  font-size: 14px;
  font-weight: 400;
}
.quiz-success-title{
  color: #4ACD7F;
  font-weight: 400;
}
.quiz-fail-title{
  color: #F51E21;
  font-weight: 400;
}
.final-correct{
  background: #fff !important;
  color: #4ACD7F !important;
  border: 1px solid #4ACD7F !important;
}
.final-wrong{
  background: #fff !important;
  color: #F51E21 !important;
  border: 1px solid #F51E21 !important;
}
.user-placeholder{
    border-radius: 8px;
    background: #E5F9FF;
    padding: 2px 5px;
}
.gray-bg{
  background: #fafafa
}

.quiz-container p{
  margin-bottom: 1rem;
}
.result-show{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 0 1rem;
}

/*quiz*/

.user-cart-data{
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #898989;
}


.family-user{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 1px solid #ECECEC;
  padding: 15px 25px;
  border-radius: 8px;
  margin-right: 10px;
  background: #f9f9f9;
  min-height: 140px;
}
.family-user.active{
  background: #fff; 
}

.family-user .user-img{
  width: 45px;
  height: 45px;
  margin: 0 10px 5px;
  border-radius: 50%;
}

.family-user span{
  color: #464646;
  font-size: 17px;
  font-weight: 400;
}

.family-user p{
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 0 0;
  color: #8B8B8B;
}

.asign-user{
  color: #FF5659 !important;
}

.popup-header{
  font-size: 20px;
  font-weight: 700;
  color: #464646;
  margin: 15px 0;
}

.package-levels{
  padding-left: 10px;
  border-left: 1px solid #8B8B8BB2;
  margin: 0 10px;
}

.package-levels span{
  margin: 0 5px;
}

.popup-desc{
  color: #989898;
  font-weight: 400;
  font-size: 17px;
  width: 90%;
  margin: auto;
}

.challenge-cart-label{
  background: rgba(235, 113, 0, 0.30);
  border-radius: 8px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 15px;

}
.book-level-btn{
  border-radius: 8px;
  border: 1px solid var(--Primary, #FF5659);
  background: rgba(255, 86, 89, 0.10);
  padding: 10px;
}

.booked-level-btn{
  border-radius: 8px;
  border: 1px solid rgba(74, 205, 127, 0.40);
  background: rgba(74, 205, 127, 0.20);
  padding: 10px;
}

.ql-editor{
  padding: 0 !important;
}
.normal-tab{
  border: none;
    border-radius: 5px;
    color: #B0B0B0;
    background: #fff;
    margin-right: 10px;
    width: calc(100% - 10px);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;;
}

.active-tab{
  background: rgba(235, 113, 0, 0.1) !important;
  color: #EB7100 !important;
}

/*rtl*/
.not-available-title{
  margin-top: 15px;
  color: #FF5B5B;
  font-weight: bold;
}
body[lang="ar"]{
  direction: rtl;
}
body[lang="ar"] .activity-icon{
  margin: 0 0 0 20px;
}
/*rtl*/
